//汎用的な文字のサイズ配列
$text-sizes: (
    tiny: 1.0rem,
    small: 1.2rem,
    normal: 1.4rem,
    large: 1.8rem,
    huge: 2.4rem,
) !default;

//汎用的な隙間のサイズ配列
$space-sizes: (
    tiny: 4px,
    small: 8px,
    normal: 16px,
    large: 32px,
    huge: 48px,
) !default;

//汎用的なボタンのサイズ配列
$button-sizes: (
    tiny: 20px,
    small: 30px,
    normal: 40px,
    large: 50px,
    huge: 60px
) !default;

//汎用的な線のサイズ配列
$line-sizes: (
    tiny: 1px,
    small: 2px,
    normal: 4px,
    large: 8px,
    huge: 16px
) !default;

//汎用的な行間のサイズ配列
$line-height-sizes: (
    tiny: 1.0,
    small: 1.2,
    normal: 1.4,
    large: 1.8,
    huge: 2.4,
) !default;