//グリッド初期設定
.op-row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    box-sizing: border-box;
    &.op-row-between {
        justify-content: space-between;
    }
    &.op-row-center {
        justify-content: center;
    }
    &.op-row-align-item-center {
        align-items: center;
    }
    > .op-col {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
        box-sizing: border-box;
    }

    //カラム12個ぶんの横幅を生成
    @for $i from 1 through 12 {
        > .op-col-#{$i} {
            flex-basis: auto !important;
            flex-grow: 0 !important;
            width: calc(100% / 12 * #{$i});
        }
        @media (max-width: 479px) {
            > .op-col-#{$i}-xs {
                flex-basis: auto !important;
                flex-grow: 0 !important;
                width: calc(100% / 12 * #{$i}) !important;
            }
        }
        @media (min-width: 480px) and (max-width: 767px) {
            > .op-col-#{$i}-sm {
                flex-basis: auto !important;
                flex-grow: 0 !important;
                width: calc(100% / 12 * #{$i}) !important;
            }
        }
        @media (min-width: 768px) {
            > .op-col-#{$i}-md {
                flex-basis: auto !important;
                flex-grow: 0 !important;
                width: calc(100% / 12 * #{$i}) !important;
            }
        }
        //本体サイトでテスト運用するための定義 SP向け
        @media (max-width: 667px) {
            > .op-col-#{$i}-sp {
                flex-basis: auto !important;
                flex-grow: 0 !important;
                width: calc(100% / 12 * #{$i}) !important;
            }
        }
        //本体サイトでテスト運用するための定義 PC向け
        @media (min-width: 668px) {
            > .op-col-#{$i}-pc {
                flex-basis: auto !important;
                flex-grow: 0 !important;
                width: calc(100% / 12 * #{$i}) !important;
            }
        }
    }
    //外側余白の大きさ
    @each $size, $value in $space-sizes {
        &.op-space-outside-#{$size} {
            > .op-col {
                margin-right: $value;
                margin-bottom: $value;
                &:last-child {
                    margin-right: 0;
                }
            }
            @for $i from 1 through 12 {
                $num: 0;
                @if $i == 2 { $num: 5; }
                @if $i == 3 { $num: 3; }
                @if $i == 4 { $num: 2; }
                @if $i == 6 { $num: 1; }
                > .op-col-#{$i} {
                    width: calc((100% - (#{$value} * #{$num})) / 12 * #{$i}) !important;
                    margin-right: $value !important;
                    margin-bottom: $value !important;
                    &:nth-child(#{12/ $i}n) {
                        margin-right: 0 !important;
                    }
                }
                @media (max-width: 479px) {
                    > .op-col-#{$i}-xs {
                        width: calc((100% - (#{$value} * #{$num})) / 12 * #{$i}) !important;
                        margin-right: $value !important;
                        margin-bottom: $value !important;
                        &:nth-child(#{12/ $i}n) {
                            margin-right: 0 !important;
                        }
                    }
                }
                @media (min-width: 480px) and (max-width: 767px) {
                    > .op-col-#{$i}-sm {
                        width: calc((100% - (#{$value} * #{$num})) / 12 * #{$i}) !important;
                        margin-right: $value !important;
                        margin-bottom: $value !important;
                        &:nth-child(#{12/ $i}n) {
                            margin-right: 0 !important;
                        }
                    }
                }
                @media (min-width: 768px) {
                    > .op-col-#{$i}-md {
                        width: calc((100% - (#{$value} * #{$num})) / 12 * #{$i}) !important;
                        margin-right: $value !important;
                        margin-bottom: $value !important;
                        &:nth-child(#{12/ $i}n) {
                            margin-right: 0 !important;
                        }
                    }
                }
                //本体サイトでテスト運用するための定義 SP向け
                @media (max-width: 667px) {
                    > .op-col-#{$i}-sp {
                        width: calc((100% - (#{$value} * #{$num})) / 12 * #{$i}) !important;
                        margin-right: $value !important;
                        margin-bottom: $value !important;
                        &:nth-child(#{12/ $i}n) {
                            margin-right: 0 !important;
                        }
                    }
                }
                //本体サイトでテスト運用するための定義 PC向け
                @media (min-width: 668px) {
                    > .op-col-#{$i}-pc {
                        width: calc((100% - (#{$value} * #{$num})) / 12 * #{$i}) !important;
                        margin-right: $value !important;
                        margin-bottom: $value !important;
                        &:nth-child(#{12/ $i}n) {
                            margin-right: 0 !important;
                        }
                    }
                }
            }
        }
        @media (max-width: 479px) {
            &.op-space-outside-#{$size}-xs {
                > .op-col {
                    margin-right: $value !important;
                    margin-bottom: $value !important;
                    &:last-child {
                        margin-right: 0 !important;
                    }
                }
                @for $i from 1 through 12 {
                    $num: 0;
                    @if $i == 2 { $num: 5; }
                    @if $i == 3 { $num: 3; }
                    @if $i == 4 { $num: 2; }
                    @if $i == 6 { $num: 1; }
                    > .op-col-#{$i}-xs {
                        width: calc((100% - (#{$value} * #{$num})) / 12 * #{$i}) !important;
                        margin-right: $value !important;
                        margin-bottom: $value !important;
                        &:nth-child(#{12/ $i}n) {
                            margin-right: 0 !important;
                        }
                    }
                }
            }
        }
        @media (min-width: 480px) and (max-width: 767px) {
            &.op-space-outside-#{$size}-sm {
                > .op-col {
                    margin-right: $value !important;
                    margin-bottom: $value !important;
                    &:last-child {
                        margin-right: 0 !important;
                    }
                }
                @for $i from 1 through 12 {
                    $num: 0;
                    @if $i == 2 { $num: 5; }
                    @if $i == 3 { $num: 3; }
                    @if $i == 4 { $num: 2; }
                    @if $i == 6 { $num: 1; }
                    > .op-col-#{$i}-sm {
                        width: calc((100% - (#{$value} * #{$num})) / 12 * #{$i}) !important;
                        margin-right: $value !important;
                        margin-bottom: $value !important;
                        &:nth-child(#{12/ $i}n) {
                            margin-right: 0 !important;
                        }
                    }
                }
            }
        }
        @media (min-width: 768px) {
            &.op-space-outside-#{$size}-md {
                > .op-col {
                    margin-right: $value !important;
                    margin-bottom: $value !important;
                    &:last-child {
                        margin-right: 0 !important;
                    }
                }
                @for $i from 1 through 12 {
                    $num: 0;
                    @if $i == 2 { $num: 5; }
                    @if $i == 3 { $num: 3; }
                    @if $i == 4 { $num: 2; }
                    @if $i == 6 { $num: 1; }
                    > .op-col-#{$i}-md {
                        width: calc((100% - (#{$value} * #{$num})) / 12 * #{$i}) !important;
                        margin-right: $value !important;
                        margin-bottom: $value !important;
                        &:nth-child(#{12/ $i}n) {
                            margin-right: 0 !important;
                        }
                    }
                }
            }
        }
        @media (max-width: 667px) {
            &.op-space-outside-#{$size}-sp {
                > .op-col {
                    margin-right: $value !important;
                    margin-bottom: $value !important;
                    &:last-child {
                        margin-right: 0 !important;
                    }
                }
                @for $i from 1 through 12 {
                    $num: 0;
                    @if $i == 2 { $num: 5; }
                    @if $i == 3 { $num: 3; }
                    @if $i == 4 { $num: 2; }
                    @if $i == 6 { $num: 1; }
                    > .op-col-#{$i}-sp {
                        width: calc((100% - (#{$value} * #{$num})) / 12 * #{$i}) !important;
                        margin-right: $value !important;
                        margin-bottom: $value !important;
                        &:nth-child(#{12/ $i}n) {
                            margin-right: 0 !important;
                        }
                    }
                }
            }
        }
        @media (min-width: 668px) {
            &.op-space-outside-#{$size}-pc {
                > .op-col {
                    margin-right: $value !important;
                    margin-bottom: $value !important;
                    &:last-child {
                        margin-right: 0 !important;
                    }
                }
                @for $i from 1 through 12 {
                    $num: 0;
                    @if $i == 2 { $num: 5; }
                    @if $i == 3 { $num: 3; }
                    @if $i == 4 { $num: 2; }
                    @if $i == 6 { $num: 1; }
                    > .op-col-#{$i}-pc {
                        width: calc((100% - (#{$value} * #{$num})) / 12 * #{$i}) !important;
                        margin-right: $value !important;
                        margin-bottom: $value !important;
                        &:nth-child(#{12/ $i}n) {
                            margin-right: 0 !important;
                        }
                    }
                }
            }
        }
    }

    //要素内を横スクロールさせる
    //$break-points: (
    //    '',
    //    -xs,
    //    -sm,
    //    -md,
    //    -sp,
    //    -pc,
    //) !default;
    //&.op-row-scroll {
    //    flex-wrap: nowrap;
    //    overflow: hidden;
    //    overflow-x: scroll;
    //}
    //@media (max-width: 479px) {
    //    &.op-row-scroll-xs {
    //        flex-wrap: nowrap;
    //        overflow: hidden;
    //        overflow-x: scroll;
    //    }
    //}
    //@media (min-width: 480px) and (max-width: 767px) {
    //    &.op-row-scroll-sm {
    //        flex-wrap: nowrap;
    //        overflow: hidden;
    //        overflow-x: scroll;
    //    }
    //}
    //@media (min-width: 768px) {
    //    &.op-row-scroll-md {
    //        flex-wrap: nowrap;
    //        overflow: hidden;
    //        overflow-x: scroll;
    //    }
    //}
    ////本体サイトでテスト運用するための定義 SP向け
    //@media (max-width: 667px) {
    //    &.op-row-scroll-sp {
    //        flex-wrap: nowrap;
    //        overflow: hidden;
    //        overflow-x: scroll;
    //    }
    //}
    ////本体サイトでテスト運用するための定義 PC向け
    //@media (min-width: 668px) {
    //    &.op-row-scroll-pc {
    //        flex-wrap: nowrap;
    //        overflow: hidden;
    //        overflow-x: scroll;
    //    }
    //}
    //@each $break-point in $break-points {
    //    &.op-row-scroll#{$break-point} {
    //        //flex-wrap: nowrap;
    //        //overflow: hidden;
    //        //overflow-x: scroll;
    //        @each $size, $value in $space-sizes {
    //            &.op-space-outside-#{$size} {
    //                > .op-col {
    //                    margin-bottom: 10px !important;
    //                }
    //                @for $i from 1 through 12 {
    //                    $num: 0;
    //                    @if $i == 2 { $num: 5; }
    //                    @if $i == 3 { $num: 3; }
    //                    @if $i == 4 { $num: 2; }
    //                    @if $i == 6 { $num: 1; }
    //                    > .op-col-#{$i} {
    //                        flex: 0 0 calc((100% - (#{$value} * #{$num})) / 12 * #{$i} - (#{$i} * 6px)) !important;
    //                        &:nth-child(#{12/ $i}n) {
    //                            margin-right: $value !important;
    //                        }
    //                    }
    //                    @media (max-width: 479px) {
    //                        > .op-col-#{$i}-xs {
    //                            flex: 0 0 calc((100% - (#{$value} * #{$num})) / 12 * #{$i} - (#{$i} * 6px)) !important;
    //                            &:nth-child(#{12/ $i}n) {
    //                                margin-right: $value !important;
    //                            }
    //                        }
    //                    }
    //                    @media (min-width: 480px) and (max-width: 767px) {
    //                        > .op-col-#{$i}-sm {
    //                            flex: 0 0 calc((100% - (#{$value} * #{$num})) / 12 * #{$i} - (#{$i} * 6px)) !important;
    //                            &:nth-child(#{12/ $i}n) {
    //                                margin-right: $value !important;
    //                            }
    //                        }
    //                    }
    //                    @media (min-width: 768px) {
    //                        > .op-col-#{$i}-md {
    //                            flex: 0 0 calc((100% - (#{$value} * #{$num})) / 12 * #{$i} - (#{$i} * 6px)) !important;
    //                            &:nth-child(#{12/ $i}n) {
    //                                margin-right: $value !important;
    //                            }
    //                        }
    //                    }
    //                    //本体サイトでテスト運用するための定義 SP向け
    //                    @media (max-width: 667px) {
    //                        > .op-col-#{$i}-sp {
    //                            flex: 0 0 calc((100% - (#{$value} * #{$num})) / 12 * #{$i} - (#{$i} * 6px)) !important;
    //                            &:nth-child(#{12/ $i}n) {
    //                                margin-right: $value !important;
    //                            }
    //                        }
    //                    }
    //                    //本体サイトでテスト運用するための定義 PC向け
    //                    @media (min-width: 668px) {
    //                        > .op-col-#{$i}-pc {
    //                            flex: 0 0 calc((100% - (#{$value} * #{$num})) / 12 * #{$i} - (#{$i} * 6px)) !important;
    //                            &:nth-child(#{12/ $i}n) {
    //                                margin-right: $value !important;
    //                            }
    //                        }
    //                    }
    //                }
    //            }
    //        }
    //    }
    //}

    //内側余白の大きさ
    @each $size, $value in $space-sizes {
        &.op-space-inside-#{$size} > .op-col {
            box-sizing: border-box;
            padding: $value;
        }
        @media (max-width: 479px) {
            &.op-space-inside-#{$size}-xs {
                @for $i from 1 through 12 {
                    > .op-col-#{$i}-xs {
                        padding: $value !important;
                    }
                }
            }
        }
        @media (min-width: 480px) and (max-width: 767px) {
            &.op-space-inside-#{$size}-sm {
                @for $i from 1 through 12 {
                    > .op-col-#{$i}-sm {
                        padding: $value !important;
                    }
                }
            }
        }
        @media (min-width: 768px) {
            &.op-space-inside-#{$size}-md {
                @for $i from 1 through 12 {
                    > .op-col-#{$i}-md {
                        padding: $value !important;
                    }
                }
            }
        }
        //本体サイトでテスト運用するための定義 SP向け
        @media (max-width: 667px) {
            &.op-space-inside-#{$size}-sp {
                @for $i from 1 through 12 {
                    > .op-col-#{$i}-sp {
                        padding: $value !important;
                    }
                }
            }
        }
        //本体サイトでテスト運用するための定義 PC向け
        @media (min-width: 668px) {
            &.op-space-inside-#{$size}-pc {
                @for $i from 1 through 12 {
                    > .op-col-#{$i}-pc {
                        padding: $value !important;
                    }
                }
            }
        }
    }
}