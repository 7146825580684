//入力フィールド
.op-form-input {
    @include _formUi;
    width: 100%;
    height: 40px;
    line-height: 40px;
    padding-right: 10px;
    padding-left: 10px;
}
//テキストエリア
.op-form-textarea {
    @include _formUi;
    width: 100%;
    padding: 10px;
}
//プルダウン
.op-form-select {
    @include _formUi;
    width: 100%;
    height: 40px;
    line-height: 40px;
    padding-right: 30px;
    padding-left: 10px;
    background-image: url(../images/icon-arrow-bottom.svg);
    background-repeat: no-repeat;
    background-position: right 10px top 10px;
    background-size: 20px auto;
    &::-ms-expand {
        display: none;
    }
}
//ラジオボタン（通常）
.op-form-radio {
    @include _formUi;
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    vertical-align: middle;
    border-radius: 50%;
    cursor: pointer;
    &:checked {
        &::before {
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            display: block;
            width: 10px;
            height: 10px;
            content: "";
            box-sizing: border-box;
            background-color: $gray-f0f0f0;
            border-radius: 50%;
        }
    }
    @each $color, $value in $colors {
        &.op-form-radio-#{$color} {
            &:checked {
                &::before {
                    background-color: $value;
                }
            }
        }
    }
}
//ラジオボタン（スイッチUI）
.op-form-switch {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid $light-gray;
    background-color: $gray-f0f0f0;
    border-radius: 20px;
    overflow: hidden;
    input[type="radio"] {
        display: none;
    }
    label {
        display: block;
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
        height: 40px;
        line-height: 40px;
        color: $gray;
        text-align:center;
        box-sizing: border-box;
        border-radius: 20px;
        cursor: pointer;
        -webkit-transition: .1s ease-out;
        -moz-transition: .1s ease-out;
        transition: .1s ease-out;
    }
    input[type="radio"]:checked + label {
        color: $white;
        background-color: $gray-f0f0f0;
    }
    @each $color, $value in $colors {
        &.op-form-switch-#{$color} {
            input[type="radio"]:checked + label {
                background-color: $value;
            }
        }
    }
}
//ファイル
.op-form-file {
    input[type="file"] {
        display: none;
    }
    //input[type="file"]:invalid + label {
    //    color: $red;
    //}
    label {
        display: block;
        height: 40px;
        line-height: 40px;
        color: $white;
        text-align: center;
        border-radius: 4px;
        background-color: $gray-f0f0f0;
        //box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
        cursor: pointer;
    }
    @each $color, $value in $colors {
        &.op-form-file-#{$color} {
            label {
                background-color: $value;
            }
        }
    }
}
//チェックボックス（通常）
.op-form-checkbox {
    @include _formUi;
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    vertical-align: middle;
    border-radius: 2px;
    cursor: pointer;
    &:checked {
        &::before {
            position: absolute;
            top: 50%;
            left: 5px;
            display: block;
            margin-top: -7px;
            width: 5px;
            height: 9px;
            border-right: 3px solid $light-gray;
            border-bottom: 3px solid $light-gray;
            content: "";
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            border-color: $gray-f0f0f0;
        }
    }
    @each $color, $value in $colors {
        &.op-form-checkbox-#{$color} {
            &:checked {
                &::before {
                    border-color: $value;
                }
            }
        }
    }
}
//チェックボックス（タグ）
.op-form-tag {
    letter-spacing: -0.4em;
    label {
        display: inline-block;
        height: 30px;
        line-height: 26px;
        color: $gray;
        letter-spacing: normal;
        box-sizing: border-box;
        margin-right: 10px;
        padding-left: 10px;
        padding-right: 10px;
        border: 2px solid $light-gray;
        border-radius: 30px;
        cursor: pointer;
        &:last-of-type {
            margin-right: 0;
        }
    }
    input[type="checkbox"] {
        display: none;
    }
    @each $color, $value in $colors {
        &.op-form-tag-#{$color} {
            input[type="checkbox"]:checked + label {
                color: $value;
                border-color: $value;
            }
        }
    }
}