//テキストの太さの配列
$text-weights: (
    normal: normal,
    bold: bold,
) !default;

//text-decorationの配列
$text-decorations: (
    underline: underline,
    line-through: line-through,
    none: none,
) !default;

//テキストの横揃えの配列
$text-aligns: (
    left: left,
    center: center,
    right: right,
) !default;

//テキストの縦揃えの配列
$vertical-aligns: (
    top: top,
    middle: middle,
    baseline: baseline,
    bottom: bottom,
) !default;

//テキストサイズ
@each $text-size, $value in $text-sizes {
    .op-text-#{$text-size} {
        font-size: $value !important;
    }
    @media (max-width: 479px) {
        .op-text-#{$text-size}-xs {
            font-size: $value !important;
        }
    }
    @media (min-width: 480px) and (max-width: 767px) {
        .op-text-#{$text-size}-sm {
            font-size: $value !important;
        }
    }
    @media (min-width: 768px) {
        .op-text-#{$text-size}-md {
            font-size: $value !important;
        }
    }
}

//10pxから40px（1remから4rem）まで2px単位のテキストサイズを生成
@for $i from 5 through 20 {
    .op-text-size#{$i * 2} {
        font-size: #{$i / 5}rem !important;
    }
    @media (max-width: 479px) {
        .op-text-size#{$i * 2}-xs {
            font-size: #{$i / 5}rem !important;
        }
    }
    @media (min-width: 480px) and (max-width: 767px) {
        .op-text-size#{$i * 2}-sm {
            font-size: #{$i / 5}rem !important;
        }
    }
    @media (min-width: 768px) {
        .op-text-size#{$i * 2}-md {
            font-size: #{$i / 5}rem !important;
        }
    }
}

//テキスト色
@each $color, $value in $colors {
    .op-text-#{$color} {
        color: $value !important;
    }
    @media (max-width: 479px) {
        .op-text-#{$color}-xs {
            color: $value !important;
        }
    }
    @media (min-width: 480px) and (max-width: 767px) {
        .op-text-#{$color}-sm {
            color: $value !important;
        }
    }
    @media (min-width: 768px) {
        .op-text-#{$color}-md {
            color: $value !important;
        }
    }
}

//テキストの太さ
@each $text-weight, $value in $text-weights {
    .op-text-weight-#{$text-weight} {
        font-weight: $value !important;
    }
    @include _screen-xs {
        .op-text-weight-#{$text-weight}-xs {
            font-weight: $value !important;
        }
    }
    @include _screen-sm {
        .op-text-weight-#{$text-weight}-sm {
            font-weight: $value !important;
        }
    }
    @include _screen-md {
        .op-text-weight-#{$text-weight}-md {
            font-weight: $value !important;
        }
    }
}

//text-decoration
@each $text-decoration, $value in $text-decorations {
    .op-text-decoration-#{$text-decoration} {
        text-decoration: $value !important;
    }
    @include _screen-xs {
        .op-text-decoration-#{$text-decoration}-xs {
            text-decoration: $value !important;
        }
    }
    @include _screen-sm {
        .op-text-decoration-#{$text-decoration}-sm {
            text-decoration: $value !important;
        }
    }
    @include _screen-md {
        .op-text-decoration-#{$text-decoration}-md {
            text-decoration: $value !important;
        }
    }
}

//テキストの横揃え
@each $text-align, $value in $text-aligns {
    .op-text-#{$text-align} {
        text-align: $value !important;
    }
    @media (max-width: 479px) {
        .op-text-#{$text-align}-xs {
            text-align: $value !important;
        }
    }
    @media (min-width: 480px) and (max-width: 767px) {
        .op-text-#{$text-align}-sm {
            text-align: $value !important;
        }
    }
    @media (min-width: 768px) {
        .op-text-#{$text-align}-md {
            text-align: $value !important;
        }
    }
}

//テキストの縦揃え
@each $vertical-align, $value in $vertical-aligns {
    .op-text-#{$vertical-align} {
        vertical-align: $value !important;
    }
    @media (max-width: 479px) {
        .op-text-#{$vertical-align}-xs {
            vertical-align: $value !important;
        }
    }
    @media (min-width: 480px) and (max-width: 767px) {
        .op-text-#{$vertical-align}-sm {
            vertical-align: $value !important;
        }
    }
    @media (min-width: 768px) {
        .op-text-#{$vertical-align}-md {
            vertical-align: $value !important;
        }
    }
}

//背景色付きの見出し
.op-title-bar {
    padding: 10px 20px;
    //背景色
    @each $color, $value in $colors {
        &.op-bg-#{$color} { background-color: $value; }
    }
}

//下線付きの大見出し
.op-title-line {
    padding: 10px 0 6px;
    //下線
    @each $color, $value in $colors {
        &.op-line-#{$color} {
            border-bottom: 4px solid $value;
        }
    }
}

//行間
@each $line-height-size, $value in $line-height-sizes {
    .op-line-height-#{$line-height-size} {
        line-height: $value !important;
    }
    @media (max-width: 479px) {
        .op-line-height-#{$line-height-size}-xs {
            line-height: $value !important;
        }
    }
    @media (min-width: 480px) and (max-width: 767px) {
        .op-line-height-#{$line-height-size}-sm {
            line-height: $value !important;
        }
    }
    @media (min-width: 768px) {
        .op-line-height-#{$line-height-size}-md {
            line-height: $value !important;
        }
    }
}
//1.0から9.9まで0.1単位で行間を生成
@for $i from 0 through 9 {
    .op-line-height#{10 + $i} {
        line-height: #{(10 + $i) / 10} !important;
    }
    .op-line-height#{20 + $i} {
        line-height: #{(20 + $i) / 10} !important;
    }
    .op-line-height#{30 + $i} {
        line-height: #{(30 + $i) / 10} !important;
    }
    .op-line-height#{40 + $i} {
        line-height: #{(40 + $i) / 10} !important;
    }
    .op-line-height#{50 + $i} {
        line-height: #{(50 + $i) / 10} !important;
    }
    .op-line-height#{60 + $i} {
        line-height: #{(60 + $i) / 10} !important;
    }
    .op-line-height#{70 + $i} {
        line-height: #{(70 + $i) / 10} !important;
    }
    .op-line-height#{80 + $i} {
        line-height: #{(80 + $i) / 10} !important;
    }
    .op-line-height#{90 + $i} {
        line-height: #{(90 + $i) / 10} !important;
    }
    @media (max-width: 479px) {
        .op-line-height#{10 + $i}-xs {
            line-height: #{(10 + $i) / 10} !important;
        }
        .op-line-height#{20 + $i}-xs {
            line-height: #{(20 + $i) / 10} !important;
        }
        .op-line-height#{30 + $i}-xs {
            line-height: #{(30 + $i) / 10} !important;
        }
        .op-line-height#{40 + $i}-xs {
            line-height: #{(40 + $i) / 10} !important;
        }
        .op-line-height#{50 + $i}-xs {
            line-height: #{(50 + $i) / 10} !important;
        }
        .op-line-height#{60 + $i}-xs {
            line-height: #{(60 + $i) / 10} !important;
        }
        .op-line-height#{70 + $i}-xs {
            line-height: #{(70 + $i) / 10} !important;
        }
        .op-line-height#{80 + $i}-xs {
            line-height: #{(80 + $i) / 10} !important;
        }
        .op-line-height#{90 + $i}-xs {
            line-height: #{(90 + $i) / 10} !important;
        }
    }
    @media (min-width: 480px) and (max-width: 767px) {
        .op-line-height#{10 + $i}-sm {
            line-height: #{(10 + $i) / 10} !important;
        }
        .op-line-height#{20 + $i}-sm {
            line-height: #{(20 + $i) / 10} !important;
        }
        .op-line-height#{30 + $i}-sm {
            line-height: #{(30 + $i) / 10} !important;
        }
        .op-line-height#{40 + $i}-sm {
            line-height: #{(40 + $i) / 10} !important;
        }
        .op-line-height#{50 + $i}-sm {
            line-height: #{(50 + $i) / 10} !important;
        }
        .op-line-height#{60 + $i}-sm {
            line-height: #{(60 + $i) / 10} !important;
        }
        .op-line-height#{70 + $i}-sm {
            line-height: #{(70 + $i) / 10} !important;
        }
        .op-line-height#{80 + $i}-sm {
            line-height: #{(80 + $i) / 10} !important;
        }
        .op-line-height#{90 + $i}-sm {
            line-height: #{(90 + $i) / 10} !important;
        }
    }
    @media (min-width: 768px) {
        .op-line-height#{10 + $i}-md {
            line-height: #{(10 + $i) / 10} !important;
        }
        .op-line-height#{20 + $i}-md {
            line-height: #{(20 + $i) / 10} !important;
        }
        .op-line-height#{30 + $i}-md {
            line-height: #{(30 + $i) / 10} !important;
        }
        .op-line-height#{40 + $i}-md {
            line-height: #{(40 + $i) / 10} !important;
        }
        .op-line-height#{50 + $i}-md {
            line-height: #{(50 + $i) / 10} !important;
        }
        .op-line-height#{60 + $i}-md {
            line-height: #{(60 + $i) / 10} !important;
        }
        .op-line-height#{70 + $i}-md {
            line-height: #{(70 + $i) / 10} !important;
        }
        .op-line-height#{80 + $i}-md {
            line-height: #{(80 + $i) / 10} !important;
        }
        .op-line-height#{90 + $i}-md {
            line-height: #{(90 + $i) / 10} !important;
        }
    }
}
