// カラー定義

// BackGround & Text
$light: #fff;
$secondary: #eee;
$tertiary: #ccc;
$primary: #666;
$dark: #333;

// Brand
$Brand: (
  light: #f4f0dc,
  secondary: #efbb40,
  tertiary: #cbb79e,
  primary: #84705b,
  dark: #3a2816,
);

// Action
$Action: (
  light: #f4faf0,
  secondary: #fffde7,
  tertiary: #f8e9d1,
  primary: #fff1ed,
  dark: #cc0033,
);

// Accent
$Accent: (
  light: #e9f5f8,
  secondary: #8dd0dd,
  tertiary: #74c2ac,
  primary: #ee7357,
  dark: #51b7cb,
);

//SNSカラー
$line: #00b900;
$facebook: #3c5a99;
$x: #0f1419;
$instagram: #cf2e92;
$google-plus: #dd5144;

// ! これより下は過去のカラー定義です。
//ブラウン
//$very-light-brown: ;
$light-brown: #cbb79e;
$brown: #84705b;
$dark-brown: #3a2816;
//$very-dark-brown: ;
//ブラウン
//$very-light-cream: ;
$light-cream: #f4f0dc;
//$cream: ;
$dark-cream: #cbb79e;
//$very-dark-cream: ;
//レッド
//$very-light-red: ;
//$light-red: ;
$red: #ee7357;
$dark-red: #cc0033;
//$very-dark-red: ;
//ブルー
//$very-light-blue: ;
$light-blue: #8dd0dd;
//$blue: ;
//$dark-blue: ;
//$very-dark-blue: ;
//グリーン
//$very-light-green: ;
//$light-green: ;
$green: #74c2ac;
//$dark-green: ;
//$very-dark-green: ;
//イエロー
//$very-light-yellow: ;
//$light-yellow: ;
$yellow: #efbb40;
//$dark-yellow: ;
//$very-dark-yellow: ;
//グレー
$very-light-gray: #eeeeee;
$light-gray: #cccccc;
$gray: #999999;
$dark-gray: #666666;
$very-dark-gray: #333333;
//ホワイト
$white: #ffffff;
//ブラック
$black: #000000;
//ピンク
$pink: #fceae6;

//サブカラー
$gray-fafafa: #fafafa;
$gray-f0f0f0: #f0f0f0;
$gray-ecedf1: #ecedf1;
$gray-f2f4f2: #f2f4f2;
$pink-fee7e7: #fee7e7;
$yellow-fffde7: #fffde7;
$yellow-f8e9d1: #f8e9d1;
$green-a5d6a7: #a5d6a7;
$green-c8e6c9: #c8e6c9;
$green-f4faf0: #f4faf0;
$blue-3362a8: #3362a8;
$blue-404a72: #404a72;
$blue-7d93ba: #7d93ba;
$blue-c5c8d4: #c5c8d4;
$blue-ccdfef: #ccdfef;

//汎用的なカラー配列
$colors: (
  // BackGround & Text
  light: $light,
  secondary: $secondary,
  tertiary: $tertiary,
  primary: $primary,
  dark: $dark,
  // ! 過去のカラー定義
  light-brown: $light-brown,
  brown: $brown,
  dark-brown: $dark-brown,
  light-cream: $light-cream,
  dark-cream: $dark-cream,
  red: $red,
  dark-red: $dark-red,
  light-blue: $light-blue,
  green: $green,
  yellow: $yellow,
  very-light-gray: $very-light-gray,
  light-gray: $light-gray,
  gray: $gray,
  dark-gray: $dark-gray,
  very-dark-gray: $very-dark-gray,
  white: $white,
  black: $black,
  pink: $pink,
  color-fafafa: $gray-fafafa,
  color-f0f0f0: $gray-f0f0f0,
  color-ecedf1: $gray-ecedf1,
  color-f2f4f2: $gray-f2f4f2,
  color-fee7e7: $pink-fee7e7,
  color-fffde7: $yellow-fffde7,
  color-f8e9d1: $yellow-f8e9d1,
  color-a5d6a7: $green-a5d6a7,
  color-c8e6c9: $green-c8e6c9,
  color-f4faf0: $green-f4faf0,
  color-3362a8: $blue-3362a8,
  color-404a72: $blue-404a72,
  color-7d93ba: $blue-7d93ba,
  color-c5c8d4: $blue-c5c8d4,
  color-ccdfef: $blue-ccdfef,
  color-line: $line,
  color-facebook: $facebook,
  color-x: $x,
  color-instagram: $instagram,
  color-google-plus: $google-plus
) !default;

// Brand, Action, Accentのカラーを追加
@each $mapName, $map in (Brand: $Brand, Action: $Action, Accent: $Accent) {
  @each $key, $value in $map {
    $colors: map-merge(
      $colors,
      (
        "#{to-lower-case($mapName)}-#{$key}": $value,
      )
    );
  }
}
