//汎用的な余白のサイズ配列
$charsets: (
    none: 0,
    auto: auto,
) !default;

//通常のマージン上下左右を生成
@each $size, $value in $space-sizes {
    .op-margin-#{$size} {
        margin: $value !important;
    }
    .op-margin-top-#{$size} {
        margin-top: $value !important;
    }
    .op-margin-bottom-#{$size} {
        margin-bottom: $value !important;
    }
    .op-margin-left-#{$size} {
        margin-left: $value !important;
    }
    .op-margin-right-#{$size} {
        margin-right: $value !important;
    }
    //「X」と「Y」方向に対で生成
    .op-margin-x-#{$size} {
        margin-left: $value !important;
        margin-right: $value !important;
    }
    .op-margin-y-#{$size} {
        margin-top: $value !important;
        margin-bottom: $value !important;
    }
    @media (max-width: 479px) {
        .op-margin-#{$size}-xs {
            margin: $value !important;
        }
        .op-margin-top-#{$size}-xs {
            margin-top: $value !important;
        }
        .op-margin-bottom-#{$size}-xs {
            margin-bottom: $value !important;
        }
        .op-margin-left-#{$size}-xs {
            margin-left: $value !important;
        }
        .op-margin-right-#{$size}-xs {
            margin-right: $value !important;
        }
        //「X」と「Y」方向に対で生成
        .op-margin-x-#{$size}-xs {
            margin-left: $value !important;
            margin-right: $value !important;
        }
        .op-margin-y-#{$size}-xs {
            margin-top: $value !important;
            margin-bottom: $value !important;
        }
    }
    @media (min-width: 480px) and (max-width: 767px) {
        .op-margin-#{$size}-sm {
            margin: $value !important;
        }
        .op-margin-top-#{$size}-sm {
            margin-top: $value !important;
        }
        .op-margin-bottom-#{$size}-sm {
            margin-bottom: $value !important;
        }
        .op-margin-left-#{$size}-sm {
            margin-left: $value !important;
        }
        .op-margin-right-#{$size}-sm {
            margin-right: $value !important;
        }
        //「X」と「Y」方向に対で生成
        .op-margin-x-#{$size}-sm {
            margin-left: $value !important;
            margin-right: $value !important;
        }
        .op-margin-y-#{$size}-sm {
            margin-top: $value !important;
            margin-bottom: $value !important;
        }
    }
    @media (min-width: 768px) {
        .op-margin-#{$size}-md {
            margin: $value !important;
        }
        .op-margin-top-#{$size}-md {
            margin-top: $value !important;
        }
        .op-margin-bottom-#{$size}-md {
            margin-bottom: $value !important;
        }
        .op-margin-left-#{$size}-md {
            margin-left: $value !important;
        }
        .op-margin-right-#{$size}-md {
            margin-right: $value !important;
        }
        //「X」と「Y」方向に対で生成
        .op-margin-x-#{$size}-md {
            margin-left: $value !important;
            margin-right: $value !important;
        }
        .op-margin-y-#{$size}-md {
            margin-top: $value !important;
            margin-bottom: $value !important;
        }
    }
}
//5pxから100pxまで5px単位のマージン上下左右を生成
@for $i from 1 through 20 {
    .op-margin-#{$i * 5} {
        margin: #{$i * 5}px !important;
    }
    .op-margin-top-#{$i * 5} {
        margin-top: #{$i * 5}px !important;
    }
    .op-margin-bottom-#{$i * 5} {
        margin-bottom: #{$i * 5}px !important;
    }
    .op-margin-left-#{$i * 5} {
        margin-left: #{$i * 5}px !important;
    }
    .op-margin-right-#{$i * 5} {
        margin-right: #{$i * 5}px !important;
    }
    //「X」と「Y」方向に対で生成
    .op-margin-x-#{$i * 5} {
        margin-left: #{$i * 5}px !important;
        margin-right: #{$i * 5}px !important;
    }
    .op-margin-y-#{$i * 5} {
        margin-top: #{$i * 5}px !important;
        margin-bottom: #{$i * 5}px !important;
    }
    @media (max-width: 479px) {
        .op-margin-#{$i * 5}-xs {
            margin: #{$i * 5}px !important;
        }
        .op-margin-top-#{$i * 5}-xs {
            margin-top: #{$i * 5}px !important;
        }
        .op-margin-bottom-#{$i * 5}-xs {
            margin-bottom: #{$i * 5}px !important;
        }
        .op-margin-left-#{$i * 5}-xs {
            margin-left: #{$i * 5}px !important;
        }
        .op-margin-right-#{$i * 5}-xs {
            margin-right: #{$i * 5}px !important;
        }
        //「X」と「Y」方向に対で生成
        .op-margin-x-#{$i * 5}-xs {
            margin-left: #{$i * 5}px !important;
            margin-right: #{$i * 5}px !important;
        }
        .op-margin-y-#{$i * 5}-xs {
            margin-top: #{$i * 5}px !important;
            margin-bottom: #{$i * 5}px !important;
        }
    }
    @media (min-width: 480px) and (max-width: 767px) {
        .op-margin-#{$i * 5}-sm {
            margin: #{$i * 5}px !important;
        }
        .op-margin-top-#{$i * 5}-sm {
            margin-top: #{$i * 5}px !important;
        }
        .op-margin-bottom-#{$i * 5}-sm {
            margin-bottom: #{$i * 5}px !important;
        }
        .op-margin-left-#{$i * 5}-sm {
            margin-left: #{$i * 5}px !important;
        }
        .op-margin-right-#{$i * 5}-sm {
            margin-right: #{$i * 5}px !important;
        }
        //「X」と「Y」方向に対で生成
        .op-margin-x-#{$i * 5}-sm {
            margin-left: #{$i * 5}px !important;
            margin-right: #{$i * 5}px !important;
        }
        .op-margin-y-#{$i * 5}-sm {
            margin-top: #{$i * 5}px !important;
            margin-bottom: #{$i * 5}px !important;
        }
    }
    @media (min-width: 768px) {
        .op-margin-#{$i * 5}-md {
            margin: #{$i * 5}px !important;
        }
        .op-margin-top-#{$i * 5}-md {
            margin-top: #{$i * 5}px !important;
        }
        .op-margin-bottom-#{$i * 5}-md {
            margin-bottom: #{$i * 5}px !important;
        }
        .op-margin-left-#{$i * 5}-md {
            margin-left: #{$i * 5}px !important;
        }
        .op-margin-right-#{$i * 5}-md {
            margin-right: #{$i * 5}px !important;
        }
        //「X」と「Y」方向に対で生成
        .op-margin-x-#{$i * 5}-md {
            margin-left: #{$i * 5}px !important;
            margin-right: #{$i * 5}px !important;
        }
        .op-margin-y-#{$i * 5}-md {
            margin-top: #{$i * 5}px !important;
            margin-bottom: #{$i * 5}px !important;
        }
    }
}
//0とautoのマージン上下左右を生成
@each $size, $value in $charsets {
    .op-margin-#{$size} {
        margin: $value !important;
    }
    .op-margin-top-#{$size} {
        margin-top: $value !important;
    }
    .op-margin-bottom-#{$size} {
        margin-bottom: $value !important;
    }
    .op-margin-left-#{$size} {
        margin-left: $value !important;
    }
    .op-margin-right-#{$size} {
        margin-right: $value !important;
    }
    //「X」と「Y」方向に対で生成
    .op-margin-x-#{$size} {
        margin-left: $value !important;
        margin-right: $value !important;
    }
    .op-margin-y-#{$size} {
        margin-top: $value !important;
        margin-bottom: $value !important;
    }
    @media (max-width: 479px) {
        .op-margin-#{$size}-xs {
            margin: $value !important;
        }
        .op-margin-top-#{$size}-xs {
            margin-top: $value !important;
        }
        .op-margin-bottom-#{$size}-xs {
            margin-bottom: $value !important;
        }
        .op-margin-left-#{$size}-xs {
            margin-left: $value !important;
        }
        .op-margin-right-#{$size}-xs {
            margin-right: $value !important;
        }
        //「X」と「Y」方向に対で生成
        .op-margin-x-#{$size}-xs {
            margin-left: $value !important;
            margin-right: $value !important;
        }
        .op-margin-y-#{$size}-xs {
            margin-top: $value !important;
            margin-bottom: $value !important;
        }
    }
    @media (min-width: 480px) and (max-width: 767px) {
        .op-margin-#{$size}-sm {
            margin: $value !important;
        }
        .op-margin-top-#{$size}-sm {
            margin-top: $value !important;
        }
        .op-margin-bottom-#{$size}-sm {
            margin-bottom: $value !important;
        }
        .op-margin-left-#{$size}-sm {
            margin-left: $value !important;
        }
        .op-margin-right-#{$size}-sm {
            margin-right: $value !important;
        }
        //「X」と「Y」方向に対で生成
        .op-margin-x-#{$size}-sm {
            margin-left: $value !important;
            margin-right: $value !important;
        }
        .op-margin-y-#{$size}-sm {
            margin-top: $value !important;
            margin-bottom: $value !important;
        }
    }
    @media (min-width: 768px) {
        .op-margin-#{$size}-md {
            margin: $value !important;
        }
        .op-margin-top-#{$size}-md {
            margin-top: $value !important;
        }
        .op-margin-bottom-#{$size}-md {
            margin-bottom: $value !important;
        }
        .op-margin-left-#{$size}-md {
            margin-left: $value !important;
        }
        .op-margin-right-#{$size}-md {
            margin-right: $value !important;
        }
        //「X」と「Y」方向に対で生成
        .op-margin-x-#{$size}-md {
            margin-left: $value !important;
            margin-right: $value !important;
        }
        .op-margin-y-#{$size}-md {
            margin-top: $value !important;
            margin-bottom: $value !important;
        }
    }
}

//通常のパディング上下左右を生成
@each $size, $value in $space-sizes {
    .op-padding-#{$size} {
        padding: $value !important;
    }
    .op-padding-top-#{$size} {
        padding-top: $value !important;
    }
    .op-padding-bottom-#{$size} {
        padding-bottom: $value !important;
    }
    .op-padding-left-#{$size} {
        padding-left: $value !important;
    }
    .op-padding-right-#{$size} {
        padding-right: $value !important;
    }
    //「X」と「Y」方向に対で生成
    .op-padding-x-#{$size} {
        padding-left: $value !important;
        padding-right: $value !important;
    }
    .op-padding-y-#{$size} {
        padding-top: $value !important;
        padding-bottom: $value !important;
    }
    @media (max-width: 479px) {
        .op-padding-#{$size}-xs {
            padding: $value !important;
        }
        .op-padding-top-#{$size}-xs {
            padding-top: $value !important;
        }
        .op-padding-bottom-#{$size}-xs {
            padding-bottom: $value !important;
        }
        .op-padding-left-#{$size}-xs {
            padding-left: $value !important;
        }
        .op-padding-right-#{$size}-xs {
            padding-right: $value !important;
        }
        //「X」と「Y」方向に対で生成
        .op-padding-x-#{$size}-xs {
            padding-left: $value !important;
            padding-right: $value !important;
        }
        .op-padding-y-#{$size}-xs {
            padding-top: $value !important;
            padding-bottom: $value !important;
        }
    }
    @media (min-width: 480px) and (max-width: 767px) {
        .op-padding-#{$size}-sm {
            padding: $value !important;
        }
        .op-padding-top-#{$size}-sm {
            padding-top: $value !important;
        }
        .op-padding-bottom-#{$size}-sm {
            padding-bottom: $value !important;
        }
        .op-padding-left-#{$size}-sm {
            padding-left: $value !important;
        }
        .op-padding-right-#{$size}-sm {
            padding-right: $value !important;
        }
        //「X」と「Y」方向に対で生成
        .op-padding-x-#{$size}-sm {
            padding-left: $value !important;
            padding-right: $value !important;
        }
        .op-padding-y-#{$size}-sm {
            padding-top: $value !important;
            padding-bottom: $value !important;
        }
    }
    @media (min-width: 768px) {
        .op-padding-#{$size}-md {
            padding: $value !important;
        }
        .op-padding-top-#{$size}-md {
            padding-top: $value !important;
        }
        .op-padding-bottom-#{$size}-md {
            padding-bottom: $value !important;
        }
        .op-padding-left-#{$size}-md {
            padding-left: $value !important;
        }
        .op-padding-right-#{$size}-md {
            padding-right: $value !important;
        }
        //「X」と「Y」方向に対で生成
        .op-padding-x-#{$size}-md {
            padding-left: $value !important;
            padding-right: $value !important;
        }
        .op-padding-y-#{$size}-md {
            padding-top: $value !important;
            padding-bottom: $value !important;
        }
    }
}
//5pxから100pxまで5px単位のパディング上下左右を生成
@for $i from 1 through 20 {
    .op-padding-#{$i * 5} {
        padding: #{$i * 5}px !important;
    }
    .op-padding-top-#{$i * 5} {
        padding-top: #{$i * 5}px !important;
    }
    .op-padding-bottom-#{$i * 5} {
        padding-bottom: #{$i * 5}px !important;
    }
    .op-padding-left-#{$i * 5} {
        padding-left: #{$i * 5}px !important;
    }
    .op-padding-right-#{$i * 5} {
        padding-right: #{$i * 5}px !important;
    }
    //「X」と「Y」方向に対で生成
    .op-padding-x-#{$i * 5} {
        padding-left: #{$i * 5}px !important;
        padding-right: #{$i * 5}px !important;
    }
    .op-padding-y-#{$i * 5} {
        padding-top: #{$i * 5}px !important;
        padding-bottom: #{$i * 5}px !important;
    }
    @media (max-width: 479px) {
        .op-padding-#{$i * 5}-xs {
            padding: #{$i * 5}px !important;
        }
        .op-padding-top-#{$i * 5}-xs {
            padding-top: #{$i * 5}px !important;
        }
        .op-padding-bottom-#{$i * 5}-xs {
            padding-bottom: #{$i * 5}px !important;
        }
        .op-padding-left-#{$i * 5}-xs {
            padding-left: #{$i * 5}px !important;
        }
        .op-padding-right-#{$i * 5}-xs {
            padding-right: #{$i * 5}px !important;
        }
        //「X」と「Y」方向に対で生成
        .op-padding-x-#{$i * 5}-xs {
            padding-left: #{$i * 5}px !important;
            padding-right: #{$i * 5}px !important;
        }
        .op-padding-y-#{$i * 5}-xs {
            padding-top: #{$i * 5}px !important;
            padding-bottom: #{$i * 5}px !important;
        }
    }
    @media (min-width: 480px) and (max-width: 767px) {
        .op-padding-#{$i * 5}-sm {
            padding: #{$i * 5}px !important;
        }
        .op-padding-top-#{$i * 5}-sm {
            padding-top: #{$i * 5}px !important;
        }
        .op-padding-bottom-#{$i * 5}-sm {
            padding-bottom: #{$i * 5}px !important;
        }
        .op-padding-left-#{$i * 5}-sm {
            padding-left: #{$i * 5}px !important;
        }
        .op-padding-right-#{$i * 5}-sm {
            padding-right: #{$i * 5}px !important;
        }
        //「X」と「Y」方向に対で生成
        .op-padding-x-#{$i * 5}-sm {
            padding-left: #{$i * 5}px !important;
            padding-right: #{$i * 5}px !important;
        }
        .op-padding-y-#{$i * 5}-sm {
            padding-top: #{$i * 5}px !important;
            padding-bottom: #{$i * 5}px !important;
        }
    }
    @media (min-width: 768px) {
        .op-padding-#{$i * 5}-md {
            padding: #{$i * 5}px !important;
        }
        .op-padding-top-#{$i * 5}-md {
            padding-top: #{$i * 5}px !important;
        }
        .op-padding-bottom-#{$i * 5}-md {
            padding-bottom: #{$i * 5}px !important;
        }
        .op-padding-left-#{$i * 5}-md {
            padding-left: #{$i * 5}px !important;
        }
        .op-padding-right-#{$i * 5}-md {
            padding-right: #{$i * 5}px !important;
        }
        //「X」と「Y」方向に対で生成
        .op-padding-x-#{$i * 5}-md {
            padding-left: #{$i * 5}px !important;
            padding-right: #{$i * 5}px !important;
        }
        .op-padding-y-#{$i * 5}-md {
            padding-top: #{$i * 5}px !important;
            padding-bottom: #{$i * 5}px !important;
        }
    }
}
//0とautoのパディング上下左右を生成
@each $size, $value in $charsets {
    .op-padding-#{$size} {
        padding: $value !important;
    }
    .op-padding-top-#{$size} {
        padding-top: $value !important;
    }
    .op-padding-bottom-#{$size} {
        padding-bottom: $value !important;
    }
    .op-padding-left-#{$size} {
        padding-left: $value !important;
    }
    .op-padding-right-#{$size} {
        padding-right: $value !important;
    }
    //「X」と「Y」方向に対で生成
    .op-padding-x-#{$size} {
        padding-left: $value !important;
        padding-right: $value !important;
    }
    .op-padding-y-#{$size} {
        padding-top: $value !important;
        padding-bottom: $value !important;
    }
    @media (max-width: 479px) {
        .op-padding-#{$size}-xs {
            padding: $value !important;
        }
        .op-padding-top-#{$size}-xs {
            padding-top: $value !important;
        }
        .op-padding-bottom-#{$size}-xs {
            padding-bottom: $value !important;
        }
        .op-padding-left-#{$size}-xs {
            padding-left: $value !important;
        }
        .op-padding-right-#{$size}-xs {
            padding-right: $value !important;
        }
        //「X」と「Y」方向に対で生成
        .op-padding-x-#{$size}-xs {
            padding-left: $value !important;
            padding-right: $value !important;
        }
        .op-padding-y-#{$size}-xs {
            padding-top: $value !important;
            padding-bottom: $value !important;
        }
    }
    @media (min-width: 480px) and (max-width: 767px) {
        .op-padding-#{$size}-sm {
            padding: $value !important;
        }
        .op-padding-top-#{$size}-sm {
            padding-top: $value !important;
        }
        .op-padding-bottom-#{$size}-sm {
            padding-bottom: $value !important;
        }
        .op-padding-left-#{$size}-sm {
            padding-left: $value !important;
        }
        .op-padding-right-#{$size}-sm {
            padding-right: $value !important;
        }
        //「X」と「Y」方向に対で生成
        .op-padding-x-#{$size}-sm {
            padding-left: $value !important;
            padding-right: $value !important;
        }
        .op-padding-y-#{$size}-sm {
            padding-top: $value !important;
            padding-bottom: $value !important;
        }
    }
    @media (min-width: 768px) {
        .op-padding-#{$size}-md {
            padding: $value !important;
        }
        .op-padding-top-#{$size}-md {
            padding-top: $value !important;
        }
        .op-padding-bottom-#{$size}-md {
            padding-bottom: $value !important;
        }
        .op-padding-left-#{$size}-md {
            padding-left: $value !important;
        }
        .op-padding-right-#{$size}-md {
            padding-right: $value !important;
        }
        //「X」と「Y」方向に対で生成
        .op-padding-x-#{$size}-md {
            padding-left: $value !important;
            padding-right: $value !important;
        }
        .op-padding-y-#{$size}-md {
            padding-top: $value !important;
            padding-bottom: $value !important;
        }
    }
}