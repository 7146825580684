@import "resets";
@import "colors";
@import "functions";
@import "sizes";
@import "icons";
// コンポーネントの読み込み
.op-container {
    @import "components/grids";
    @import "components/buttons";
    @import "components/texts";
    @import "components/lists";
    @import "components/tables";
    @import "components/frames";
    @import "components/boxes";
    @import "components/forms";
    @import "components/cards";
    @import "components/pagination";
    @import "components/badges";
    @import "components/balloons";
    @import "components/spaces";
    @import "components/displays";
    @import "components/borders";
    @import "components/widthes";
}

/*
TODO:森メモ
・変数や関数の書式（スネークケース、キャメルケース）
　関数は_から始めるキャメル
　変数はハイフン小文字
　配列は複数形
・olはlist-style-type: decimalで実装したほうが良い？
　list-style-typeだと<ol start="5">で途中から番号を振ったりも可能
・functionのまとめ場所（全部_functions.scss？か各パーツに関するものはパーツのscssに書く？）
 */
