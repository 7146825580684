//表組み初期設定
.op-table {
    border-collapse: collapse;
    box-sizing: border-box;
    tr {
        background-color: $white;
        &:nth-of-type(even) {
            background-color: $gray-fafafa;
        }
    }
    th, td {
        border-collapse: collapse;
        text-align: left;
        font-weight: normal;
        box-sizing: border-box;
        padding: 8px;
    }
    th {
        background-color: $light-cream;
    }
    thead {
        th {
            border-right: 2px solid $white;
            &:last-of-type {
                border-right: none;
            }
        }
    }
    tbody {
        tr {
            th {
                border-bottom: 2px solid $white;
            }
            &:last-of-type {
                th {
                    border-bottom: none;
                }
            }
        }
    }
    //線の色
    @each $color, $value in $colors {
        &.op-border-#{$color} {
            border: 1px solid $value;
            thead, tbody {
                th, td {
                    border: 1px solid $value;
                }
            }
        }
    }
    //ブレイクポイント単位で表組みを崩す
    @media (max-width: 479px) {
        &.op-break-xs {
            @include _breakTable;
        }
    }
    @media (min-width: 480px) and (max-width: 767px) {
        &.op-break-sm {
            @include _breakTable;
        }
    }
    @media (min-width: 768px) {
        &.op-break-md {
            @include _breakTable;
        }
    }
    @media (max-width: 667px) {
        &.op-break-sp {
            @include _breakTable;
        }
    }
    @media (min-width: 668px) {
        &.op-break-pc {
            @include _breakTable;
        }
    }
}