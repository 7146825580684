html {
    font-size: 62.5%; //10px相当
    //font-size: 75.0%; 12px相当
    //font-size: 87.5%; 14px相当
}

body {
    -webkit-text-size-adjust: 100%;
    font-size: 1.4rem;
    margin: 0;
    padding: 0;
    border: 0;
}

.op-container {
    div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed,
    figure, figcaption, footer, header, hgroup,
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video,
    button, input, textarea, select, option {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        outline: none;
    }
    h1, h2, h3, h4, h5, h6, p, table, dl, ol, ul {
        margin-bottom: 1.0em; //試してみて違和感あったら rem を検討
    }
    figure { //TODO:要確認
        //margin-bottom: 1.0em;
    }
    img { //TODO:要確認
        max-width: 100%;
        box-sizing: border-box;
    }
    ol, ul {
        list-style: none;
    }

    table, thead, tbody, tfoot, tr, th, td {
        border-collapse: collapse;
        border-spacing: 0;
    }

    pre, code {
        width: 100%;
        overflow: hidden;
        overflow-x: scroll;
    }
}
