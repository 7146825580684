@font-face {
    font-family: 'icomoon';
    src: url('../fonts/icomoon.eot?iqwk4x');
    src: url('../fonts/icomoon.eot?iqwk4x#iefix') format('embedded-opentype'),
        url('../fonts/icomoon.ttf?iqwk4x') format('truetype'),
        url('../fonts/icomoon.woff?iqwk4x') format('woff'),
        url('../fonts/icomoon.svg?iqwk4x#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

//アイコンのコード割り当て
$icon-arrow-double: "\e909";
$icon-arrow: "\e90a";
$icon-calendar: "\e90b";
$icon-check: "\e90c";
$icon-circle: "\e90d";
$icon-yen: "\e90e";
$icon-email: "\e90f";
$icon-heart: "\e910";
$icon-home: "\e911";
$icon-pin: "\e912";
$icon-search: "\e913";
$icon-dotted: "\e914";
$icon-camera: "\e915";
$icon-plus: "\e916";
$icon-balloon: "\e917";
$icon-star: "\e918";
$icon-clock: "\e919";
$icon-warning: "\e91a";
$icon-x: "\e91b";
$icon-google-plus: "\ea8c";
$icon-facebook: "\ea91";
$icon-instagram: "\ea92";
$icon-twitter: "\ea96";
$icon-pencil: "\e924";
$icon-tag: "\e935";
$icon-mobile: "\e958";
$icon-download: "\e960";
$icon-upload: "\e961";
$icon-trashcan: "\e9ac";
$icon-book: "\e904";
$icon-clipboard: "\e905";
$icon-gear: "\e906";
$icon-pictures: "\e907";
$icon-picture: "\e908";
$icon-yen-circle: "\e900";
$icon-gift: "\e901";
$icon-help: "\e902";
$icon-person: "\e903";
$icon-star-fill: "\e9d9";
$icon-heart-fill: "\e9da";
$icon-graph-bar: "\e927";
$icon-graph-line: "\e928";
$icon-key: "\e929";
$icon-smile: "\e92a";
$icon-writing: "\e92b";
$icon-hand-up: "\e92c";

//アイコンの配列
$icons: (arrow-double: $icon-arrow-double,
    arrow: $icon-arrow,
    calendar: $icon-calendar,
    check: $icon-check,
    circle: $icon-circle,
    yen: $icon-yen,
    email: $icon-email,
    heart: $icon-heart,
    home: $icon-home,
    pin: $icon-pin,
    search: $icon-search,
    dotted: $icon-dotted,
    camera: $icon-camera,
    plus: $icon-plus,
    balloon: $icon-balloon,
    star: $icon-star,
    clock: $icon-clock,
    warning: $icon-warning,
    x: $icon-x,
    google-plus: $icon-google-plus,
    facebook: $icon-facebook,
    instagram: $icon-instagram,
    twitter: $icon-twitter,
    pencil: $icon-pencil,
    tag: $icon-tag,
    mobile: $icon-mobile,
    download: $icon-download,
    upload: $icon-upload,
    trashcan: $icon-trashcan,
    book: $icon-book,
    clipboard: $icon-clipboard,
    gear: $icon-gear,
    pictures: $icon-pictures,
    picture: $icon-picture,
    yen-circle: $icon-yen-circle,
    gift: $icon-gift,
    help: $icon-help,
    person: $icon-person,
    star-fill: $icon-star-fill,
    heart-fill: $icon-heart-fill,
    graph-bar: $icon-graph-bar,
    graph-line: $icon-graph-line,
    key: $icon-key,
    smile: $icon-smile,
    writing: $icon-writing,
    hand-up: $icon-hand-up) !default;

.op-container {

    [class^="op-icon-"],
    [class*=" op-icon-"] {
        @include _fontIcon;

        .op-path {
            font-family: 'icomoon' !important;
        }
    }

    @each $icon,
    $value in $icons {
        .op-icon-#{$icon}:before {
            content: $value;
        }
    }

    // 複数色のアイコン
    @for $i from 2 through 10 {
        .op-icon .op-path:nth-of-type(#{$i}) {
            margin-left: -#{$i - 1}em;
        }
    }

    .op-icon-smiley-very-fine .op-path:nth-of-type(1):before {
        content: "\e91c";
        color: $yellow;
    }

    .op-icon-smiley-very-fine .op-path:nth-of-type(2):before {
        content: "\e91d";
        color: $black;
    }

    .op-icon-smiley-fine .op-path:nth-of-type(1):before {
        content: "\e91e";
        color: $yellow;
    }

    .op-icon-smiley-fine .op-path:nth-of-type(2):before {
        content: "\e91f";
        color: $black;
    }

    .op-icon-smiley-neutral .op-path:nth-of-type(1):before {
        content: "\e920";
        color: $light-cream;
    }

    .op-icon-smiley-neutral .op-path:nth-of-type(2):before {
        content: "\e921";
        color: $black;
    }

    .op-icon-smiley-sad .op-path:nth-of-type(1):before {
        content: "\e922";
        color: $light-gray;
    }

    .op-icon-smiley-sad .op-path:nth-of-type(2):before {
        content: "\e923";
        color: $black;
    }

    .op-icon-smiley-very-sad .op-path:nth-of-type(1):before {
        content: "\e925";
        color: $light-gray;
    }

    .op-icon-smiley-very-sad .op-path:nth-of-type(2):before {
        content: "\e926";
        color: $black;
    }

    // アイコンの角度変更
    .op-icon-rotate-90 {
        transform: rotate(90deg);
        display: inline-block;
    }

    .op-icon-rotate-180 {
        transform: rotate(180deg);
        display: inline-block;
    }

    .op-icon-rotate-270 {
        transform: rotate(270deg);
        display: inline-block;
    }
}