//基本のページネーション
.op-pagination {
    display: -webkit-flex;
    display: flex;
    line-height: 1;
    > * {
        width: 45px;
        border-right: 1px solid $white;
        border-left: 1px solid $white;
        &.op-current {
            -webkit-flex: 1 1 1px;
            flex: 1 1 1px;
            width: auto;
            height: 45px;
            background: $gray-f0f0f0;
            color: $gray;
            text-align: center;
            line-height: 45px;
        }
        a {
            display: block;
            position: relative;
            overflow: hidden;
            height: 45px;
            background: $gray-f0f0f0;
            text-decoration: none;
            text-indent: 100%;
            white-space: nowrap;
            @include _hover;
            &[href=""],
            &:not([href]) {
                pointer-events: none;
                background: $gray-f0f0f0;
            }
        }
        &.op-first a:before,
        &.op-prev a:before,
        &.op-next a:before,
        &.op-last a:before {
            display: block;
            position: absolute;
            left: 50%;
            top: 50%;
            color: #ffffff;
            font-size: 1.8rem;
            text-indent: 0;
            line-height: 1.8rem;
            @include _fontIcon;
        }
        &.op-first a:before,
        &.op-prev a:before {
            -webkit-transform: translate(-50%, -50%) rotate(180deg);
            transform: translate(-50%, -50%) rotate(180deg);
        }
        &.op-next a:before,
        &.op-last a:before {
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
        }
        &.op-prev a:before,
        &.op-next a:before {
            content: $icon-arrow;
        }
        &.op-first a:before,
        &.op-last a:before {
            content: $icon-arrow-double;
        }
    }
    //背景色
    @each $color, $value in $colors {
        &.op-bg-#{$color} > * a {
            background-color: $value;
            &:not([href]),
            &[href=""] {
                pointer-events: none;
                background: $gray-f0f0f0;
            }
        }
    }
}
