//非表示・非表示種類の配列
$displays: (
    none: none,
    inline: inline,
    inline-block: inline-block,
    block: block
) !default;

//表示・非表示の種類を生成
@each $size, $value in $displays {
    .op-display-#{$size} {
        display: $value !important;
    }
    @media (max-width: 479px) {
        .op-display-#{$size}-xs {
            display: $value !important;
        }
    }
    @media (min-width: 480px) and (max-width: 767px) {
        .op-display-#{$size}-sm {
            display: $value !important;
        }
    }
    @media (min-width: 768px) {
        .op-display-#{$size}-md {
            display: $value !important;
        }
    }
}