//各ブレイクポイントのメディアクエリを出力
@mixin _screen-xs {
    @media (max-width: 479px) {
        @content
    }
}
@mixin _screen-sm {
    @media (min-width: 480px) and (max-width: 767px) {
        @content
    }
}
@mixin _screen-md {
    @media (min-width: 768px) {
        @content
    }
}

//横幅固定サイズの display:block を中央配置
@mixin _centering {
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
}
//文字サイズ
@mixin _fontSize($size) {
    @if $size == "tiny"  { font-size: 1.0rem; }
    @if $size == "small" { font-size: 1.2rem; }
    @if $size == "large" { font-size: 1.8rem; }
    @if $size == "huge"  { font-size: 2.4rem; }
}
//hoverアニメーションの初期値
@mixin _hover {
    -webkit-transition: .3s ease-out;
    -moz-transition: .3s ease-out;
    transition: .3s ease-out;
    &:hover { opacity: .65; }
}
//ボタンの初期値
@mixin _button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    color: $white;
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    box-sizing: border-box;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
    outline: none;
    //-webkit-transition: .3s ease-out;
    //-moz-transition: .3s ease-out;
    //transition: .3s ease-out;
    //&:hover { opacity: .65; }
}
//フォントアイコンの初期値
@mixin _fontIcon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    vertical-align: middle;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
//フォームパーツの初期値
@mixin _formUi {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid $light-gray;
    background-color: $white;
    outline: none;
}
//バッジの初期化
@mixin _badge {
    display: inline-block;
    color: $gray-f0f0f0;
    line-height: 1.0;
    box-sizing: border-box;
    padding: 3px 10px;
    border-radius: 100px;
    background-color: $gray-f0f0f0;
}
//吹き出しの初期化
@mixin _balloon {
    position: relative;
    width: 100%;
    text-align: left;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 4px;
    background-color: $gray-f0f0f0;
    &:before {
        content: "";
        position: absolute;
        height: 0;
        width: 0;
    }
}
//表組みの崩し
@mixin _breakTable {
    display: block;
    border-top: none;
    border-left: none;
    border-right: none;
    thead, tbody {
        display: block;
        tr {
            display: block;
            th, td {
                display: block;
                border-bottom: none;
            }
        }
    }
}
