//通常バッジ
.op-badge {
    @include _badge;
    color: $white;
    //バッジの色
    @each $color, $value in $colors {
        &.op-bg-#{$color} { background-color: $value; }
    }
    //バッジの大きさ
    @each $size, $value in $button-sizes {
        &.op-badge-#{$size} {
            @include _fontSize($size);
            @if $size == "normal" { padding-top: 0.4em; padding-bottom: 0.4em; }
            @if $size == "large" { padding-top: 0.4em; padding-bottom: 0.4em; }
            @if $size == "huge"  { padding-top: 0.4em; padding-bottom: 0.4em; }
        }
    }
}
//枠線バッジ
.op-badge-border {
    @include _badge;
    //バッジの色
    @each $color, $value in $colors {
        &.op-border-#{$color} {
            color: $value;
            border: 2px solid $value;
            background-color: transparent;
        }
    }
    //バッジの大きさ
    @each $size, $value in $button-sizes {
        &.op-badge-#{$size} {
            @include _fontSize($size);
        }
    }
}
