//カラーボックス
.op-color-box,
.op-color-box-xs,
.op-color-box-sm,
.op-color-box-md {
    box-sizing: border-box;
    //角丸フレーム
    &.op-box-round {
        border-radius: 4px;
    }
    //ボックスの色
    @each $color, $value in $colors {
        &.op-bg-#{$color} { background-color: $value; }
    }
    @media (max-width: 479px) {
        //角丸フレーム
        &.op-box-round-xs {
            border-radius: 4px;
        }
        //ボックスの色
        @each $color, $value in $colors {
            &.op-bg-#{$color}-xs { background-color: $value; }
        }
    }
    @media (min-width: 480px) and (max-width: 767px) {
        //角丸フレーム
        &.op-box-round-sm {
            border-radius: 4px;
        }
        //ボックスの色
        @each $color, $value in $colors {
            &.op-bg-#{$color}-sm { background-color: $value; }
        }
    }
    @media (min-width: 768px) {
        //角丸フレーム
        &.op-box-round-md {
            border-radius: 4px;
        }
        //ボックスの色
        @each $color, $value in $colors {
            &.op-bg-#{$color}-md { background-color: $value; }
        }
    }
}

//アラートボックス
.op-alert-box {
    display: block;
    overflow: hidden;
    //margin-bottom: 1.0em; //要確認（無しの方向で）
    border: 2px solid $gray-f0f0f0;
    border-radius: 6px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.24);

    //フレームの色
    @each $color, $value in $colors {
        &.op-border-#{$color} { border-color: $value; }
    }

    > *:first-of-type {
        background-color: $gray-f0f0f0;
    }

    //背景色
    @each $color, $value in $colors {
        > .op-bg-#{$color} { background-color: $value; }
    }
}
a.op-alert-box {
    text-decoration: none;
    @include _hover;
}