//横幅いっぱいを生成
.op-width-full {
    width: 100% !important;
    box-sizing: border-box;
}
@include _screen-xs {
    .op-width-full-xs {
        width: 100% !important;
        box-sizing: border-box;
    }
}
@include _screen-sm {
    .op-width-full-sm {
        width: 100% !important;
        box-sizing: border-box;
    }
}
@include _screen-md {
    .op-width-full-md {
        width: 100% !important;
        box-sizing: border-box;
    }
}

//横幅autoを生成
.op-width-auto {
    width: auto !important;
}
@include _screen-xs {
    .op-width-auto-xs {
        width: auto !important;
    }
}
@include _screen-sm {
    .op-width-auto-sm {
        width: auto !important;
    }
}
@include _screen-md {
    .op-width-auto-md {
        width: auto !important;
    }
}

@for $i from 1 through 10 {
    //100pxから1000pxまで100px単位で横幅を生成
    .op-width#{$i * 100} {
        width: #{$i * 100}px !important;
        @include _centering;
    }
    @media (max-width: 479px) {
        .op-width#{$i * 100} {
            width: 100% !important;
        }
    }
    @media (min-width: 480px) and (max-width: 767px) {
        .op-width#{$i * 100} {
            width: 100% !important;
        }
    }
    @media (min-width: 768px) {
    }
    //100pxから1000pxまで100px単位で最大の横幅を生成
    .op-max-width#{$i * 100} {
        max-width: #{$i * 100}px !important;
        @include _centering;
    }
    @media (max-width: 479px) {
        .op-max-width#{$i * 100}-xs {
            max-width: #{$i * 100}px !important;
            @include _centering;
        }
    }
    @media (min-width: 480px) and (max-width: 767px) {
        .op-max-width#{$i * 100}-sm {
            max-width: #{$i * 100}px !important;
            @include _centering;
        }
    }
    @media (min-width: 768px) {
        .op-max-width#{$i * 100}-md {
            max-width: #{$i * 100}px !important;
            @include _centering;
        }
    }
    //100pxから1000pxまで100px単位で最小の横幅を生成
    .op-min-width#{$i * 100} {
        min-width: #{$i * 100}px !important;
        @include _centering;
    }
    @media (max-width: 479px) {
        .op-min-width#{$i * 100} {
            min-width: 100% !important;
        }
    }
    @media (min-width: 480px) and (max-width: 767px) {
        .op-min-width#{$i * 100} {
            min-width: 100% !important;
        }
    }
    @media (min-width: 768px) {
    }
    //5%から100%まで5%単位で横幅を生成
    .op-width#{$i * 10}percent {
        width: #{$i * 10}% !important;
        @include _centering;
    }
    .op-width#{$i * 10 + 5}percent {
        width: #{$i * 10 + 5}% !important;
        @include _centering;
    }
    @media (max-width: 479px) {
        .op-width#{$i * 10}percent-xs {
            width: #{$i * 10}% !important;
            @include _centering;
        }
        .op-width#{$i * 10 + 5}percent-xs {
            width: #{$i * 10 + 5}% !important;
            @include _centering;
        }
    }
    @media (min-width: 480px) and (max-width: 767px) {
        .op-width#{$i * 10}percent-sm {
            width: #{$i * 10}% !important;
            @include _centering;
        }
        .op-width#{$i * 10 + 5}percent-sm {
            width: #{$i * 10 + 5}% !important;
            @include _centering;
        }
    }
    @media (min-width: 768px) {
        .op-width#{$i * 10}percent-md {
            width: #{$i * 10}% !important;
            @include _centering;
        }
        .op-width#{$i * 10 + 5}percent-md {
            width: #{$i * 10 + 5}% !important;
            @include _centering;
        }
    }
}
