//汎用的な余白のサイズ配列
$lines: (
    solid: solid,
    dashed: dashed,
    dotted: dotted,
    double: double
) !default;
//上下左右の配列
$line-positions: (
    top: top,
    left: left,
    right: right,
    bottom: bottom
) !default;

//枠線
.op-border {
    border: 1px solid $gray-f0f0f0;
}
//線の色
@each $color, $value in $colors {
    .op-border-#{$color} { border-color: $value !important; }
}
//線の種類
@each $type, $value in $lines {
    .op-border-#{$type} { border-style: $value !important; }
}
//線の太さ（文字定義）
@each $size, $value in $line-sizes {
    .op-border-#{$size} { border-width: $value !important; }
}
//線の太さ（数値定義）
@for $i from 1 through 10 {
    .op-border-size#{$i} { border-width: #{$i}px !important; }
}
@media (max-width: 479px) {
    .op-border-xs {
        border: 1px solid $gray-f0f0f0;
    }
    //線の色
    @each $color, $value in $colors {
        .op-border-#{$color}-xs { border-color: $value !important; }
    }
    //線の種類
    @each $type, $value in $lines {
        .op-border-#{$type}-xs { border-style: $value !important; }
    }
    //線の太さ（文字定義）
    @each $size, $value in $line-sizes {
        .op-border-#{$size}-xs { border-width: $value !important; }
    }
    //線の太さ（数値定義）
    @for $i from 1 through 10 {
        .op-border-size#{$i}-xs { border-width: #{$i}px !important; }
    }
}
@media (min-width: 480px) and (max-width: 767px) {
    .op-border-sm {
        border: 1px solid $gray-f0f0f0;
    }
    //線の色
    @each $color, $value in $colors {
        .op-border-#{$color}-sm { border-color: $value !important; }
    }
    //線の種類
    @each $type, $value in $lines {
        .op-border-#{$type}-sm { border-style: $value !important; }
    }
    //線の太さ（文字定義）
    @each $size, $value in $line-sizes {
        .op-border-#{$size}-sm { border-width: $value !important; }
    }
    //線の太さ（数値定義）
    @for $i from 1 through 10 {
        .op-border-size#{$i}-sm { border-width: #{$i}px !important; }
    }
}
@media (min-width: 768px) {
    .op-border-md {
        border: 1px solid $gray-f0f0f0;
    }
    //線の色
    @each $color, $value in $colors {
        .op-border-#{$color}-md { border-color: $value !important; }
    }
    //線の種類
    @each $type, $value in $lines {
        .op-border-#{$type}-md { border-style: $value !important; }
    }
    //線の太さ（文字定義）
    @each $size, $value in $line-sizes {
        .op-border-#{$size}-md { border-width: $value !important; }
    }
    //線の太さ（数値定義）
    @for $i from 1 through 10 {
        .op-border-size#{$i}-md { border-width: #{$i}px !important; }
    }
}
//上下左右の個別線
@each $key, $pos in $line-positions {
    .op-border-#{$pos} {
        border-#{$pos}: 1px solid $gray-f0f0f0;
    }
    //線の色
    @each $color, $value in $colors {
        .op-border-#{$pos}-#{$color} { border-#{$pos}-color: $value !important; }
    }
    //線の種類
    @each $type, $value in $lines {
        .op-border-#{$pos}-#{$type} { border-#{$pos}-style: $value !important; }
    }
    //線の太さ（文字定義）
    @each $size, $value in $line-sizes {
        .op-border-#{$pos}-#{$size} { border-#{$pos}-width: $value !important; }
    }
    //線の太さ（数値定義）
    @for $i from 1 through 10 {
        .op-border-#{$pos}-size#{$i} { border-#{$pos}-width: #{$i}px !important; }
    }
    @media (max-width: 479px) {
        .op-border-#{$pos}-xs {
            border-#{$pos}: 1px solid $gray-f0f0f0;
        }
        //線の色
        @each $color, $value in $colors {
            .op-border-#{$pos}-#{$color}-xs { border-#{$pos}-color: $value !important; }
        }
        //線の種類
        @each $type, $value in $lines {
            .op-border-#{$pos}-#{$type}-xs { border-#{$pos}-style: $value !important; }
        }
        //線の太さ（文字定義）
        @each $size, $value in $line-sizes {
            .op-border-#{$pos}-#{$size}-xs { border-#{$pos}-width: $value !important; }
        }
        //線の太さ（数値定義）
        @for $i from 1 through 10 {
            .op-border-#{$pos}-size#{$i}-xs { border-#{$pos}-width: #{$i}px !important; }
        }
    }
    @media (min-width: 480px) and (max-width: 767px) {
        .op-border-#{$pos}-sm {
            border-#{$pos}: 1px solid $gray-f0f0f0;
        }
        //線の色
        @each $color, $value in $colors {
            .op-border-#{$pos}-#{$color}-sm { border-#{$pos}-color: $value !important; }
        }
        //線の種類
        @each $type, $value in $lines {
            .op-border-#{$pos}-#{$type}-sm { border-#{$pos}-style: $value !important; }
        }
        //線の太さ（文字定義）
        @each $size, $value in $line-sizes {
            .op-border-#{$pos}-#{$size}-sm { border-#{$pos}-width: $value !important; }
        }
        //線の太さ（数値定義）
        @for $i from 1 through 10 {
            .op-border-#{$pos}-size#{$i}-sm { border-#{$pos}-width: #{$i}px !important; }
        }
    }
    @media (min-width: 768px) {
        .op-border-#{$pos}-md {
            border-#{$pos}: 1px solid $gray-f0f0f0;
        }
        //線の色
        @each $color, $value in $colors {
            .op-border-#{$pos}-#{$color}-md { border-#{$pos}-color: $value !important; }
        }
        //線の種類
        @each $type, $value in $lines {
            .op-border-#{$pos}-#{$type}-md { border-#{$pos}-style: $value !important; }
        }
        //線の太さ（文字定義）
        @each $size, $value in $line-sizes {
            .op-border-#{$pos}-#{$size}-md { border-#{$pos}-width: $value !important; }
        }
        //線の太さ（数値定義）
        @for $i from 1 through 10 {
            .op-border-#{$pos}-size#{$i}-md { border-#{$pos}-width: #{$i}px !important; }
        }
    }
}