//吹き出し
.op-balloon {
    @include _balloon;
    //引き出しの色
    @each $color, $value in $colors {
        &.op-bg-#{$color} {
            background-color: $value;
        }
    }
    //矢印（下側・左）
    &.op-balloon-arrow-bottom-left {
        &:before {
            bottom: 0;
            left: 20px;
            transform: translateY(100%);
            border: 5px solid transparent;
            border-top: 7px solid $gray-f0f0f0; //13px
        }
        //矢印の色
        @each $color, $value in $colors {
            &.op-bg-#{$color} {
                &:before {
                    border-top-color: $value;
                }
            }
        }
    }
    //矢印（下側・中）
    &.op-balloon-arrow-bottom-center {
        &:before {
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 100%);
            border: 5px solid transparent;
            border-top: 7px solid $gray-f0f0f0; //13px
        }
        //矢印の色
        @each $color, $value in $colors {
            &.op-bg-#{$color} {
                &:before {
                    border-top-color: $value;
                }
            }
        }
    }
    //矢印（下側・右）
    &.op-balloon-arrow-bottom-right {
        &:before {
            bottom: 0;
            right: 20px;
            transform: translateY(100%);
            border: 5px solid transparent;
            border-top: 7px solid $gray-f0f0f0; //13px
        }
        //矢印の色
        @each $color, $value in $colors {
            &.op-bg-#{$color} {
                &:before {
                    border-top-color: $value;
                }
            }
        }
    }
    //矢印（左側・上）
    &.op-balloon-arrow-left-top {
        width: calc(100% - 7px);
        margin-left: 7px;
        &:before {
            top: 10px;
            left: -11px;
            transform: rotate(90deg);
            border: 5px solid transparent;
            border-top: 7px solid $gray-f0f0f0; //13px
        }
        //矢印の色
        @each $color, $value in $colors {
            &.op-bg-#{$color} {
                &:before {
                    border-top-color: $value;
                }
            }
        }
    }
    //矢印（左側・中）
    &.op-balloon-arrow-left-center {
        width: calc(100% - 7px);
        margin-left: 7px;
        &:before {
            top: 50%;
            left: -11px;
            transform: translateY(-50%) rotate(90deg);
            border: 5px solid transparent;
            border-top: 7px solid $gray-f0f0f0; //13px
        }
        //矢印の色
        @each $color, $value in $colors {
            &.op-bg-#{$color} {
                &:before {
                    border-top-color: $value;
                }
            }
        }
    }
    //矢印（左側・下）
    &.op-balloon-arrow-left-bottom {
        width: calc(100% - 7px);
        margin-left: 7px;
        &:before {
            bottom: 10px;
            left: -11px;
            transform: rotate(90deg);
            border: 5px solid transparent;
            border-top: 7px solid $gray-f0f0f0; //13px
        }
        //矢印の色
        @each $color, $value in $colors {
            &.op-bg-#{$color} {
                &:before {
                    border-top-color: $value;
                }
            }
        }
    }
    //矢印（右側・上）
    &.op-balloon-arrow-right-top {
        width: calc(100% - 7px);
        &:before {
            top: 10px;
            right: -11px;
            transform: rotate(-90deg);
            border: 5px solid transparent;
            border-top: 7px solid $gray-f0f0f0; //13px
        }
        //矢印の色
        @each $color, $value in $colors {
            &.op-bg-#{$color} {
                &:before {
                    border-top-color: $value;
                }
            }
        }
    }
    //矢印（右側・中）
    &.op-balloon-arrow-right-center {
        width: calc(100% - 7px);
        &:before {
            top: 50%;
            right: -11px;
            transform: translateY(-50%) rotate(-90deg);
            border: 5px solid transparent;
            border-top: 7px solid $gray-f0f0f0; //13px
        }
        //矢印の色
        @each $color, $value in $colors {
            &.op-bg-#{$color} {
                &:before {
                    border-top-color: $value;
                }
            }
        }
    }
    //矢印（右側・下）
    &.op-balloon-arrow-right-bottom {
        width: calc(100% - 7px);
        &:before {
            bottom: 10px;
            right: -11px;
            transform: rotate(-90deg);
            border: 5px solid transparent;
            border-top: 7px solid $gray-f0f0f0; //13px
        }
        //矢印の色
        @each $color, $value in $colors {
            &.op-bg-#{$color} {
                &:before {
                    border-top-color: $value;
                }
            }
        }
    }
    //矢印（上側・左）
    &.op-balloon-arrow-top-left {
        &:before {
            top: 0;
            left: 20px;
            transform: translateY(-100%) rotate(180deg);
            border: 5px solid transparent;
            border-top: 7px solid $gray-f0f0f0; //13px
        }
        //矢印の色
        @each $color, $value in $colors {
            &.op-bg-#{$color} {
                &:before {
                    border-top-color: $value;
                }
            }
        }
    }
    //矢印（上側・中）
    &.op-balloon-arrow-top-center {
        &:before {
            top: 0;
            left: 50%;
            transform: translate(-50%, -100%) rotate(180deg);
            border: 5px solid transparent;
            border-top: 7px solid $gray-f0f0f0; //13px
        }
        //矢印の色
        @each $color, $value in $colors {
            &.op-bg-#{$color} {
                &:before {
                    border-top-color: $value;
                }
            }
        }
    }
    //矢印（上側・右）
    &.op-balloon-arrow-top-right {
        &:before {
            top: 0;
            right: 20px;
            transform: translateY(-100%) rotate(180deg);
            border: 5px solid transparent;
            border-top: 7px solid $gray-f0f0f0; //13px
        }
        //矢印の色
        @each $color, $value in $colors {
            &.op-bg-#{$color} {
                &:before {
                    border-top-color: $value;
                }
            }
        }
    }
}
