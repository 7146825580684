//黒ポッチリスト
.op-list-dot {
    li {
        position: relative;
        padding-left: 1.5em;
        &:before {
            display: block;
            position: absolute;
            left: 0;
            top: 0.2em;
            width: 1.6em;
            font-weight: bold;
            line-height: 1;
            content: "・";
        }
    }
}

//ナンバリングリスト
.op-list-number {
    counter-reset: list-counter;
    li {
        position: relative;
        padding-left: 1.6em;
        &:before {
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 1.6em;
            content: counter(list-counter) ".";
            counter-increment: list-counter;
        }
    }
}

//汎用リスト
.op-list {
    &:before {
        display: none;
    }
    li {
        position: relative;
        padding-left: 1.5em;
        line-height: 1.6; //TODO:要確認
        i:first-of-type {
            display: block;
            position: absolute;
            left: 0;
            top: .3em;
            width: 1.6em;
            @include _fontIcon;
            &:not(.op-icon) {
                &:before {
                    content: $icon-arrow;
                }
            }
        }
        a + i:first-of-type {
            display: inline-block;
            position: static;
            width: auto;
        }
    }
    @each $icon, $value in $icons {
        &.op-icon-#{$icon} li i:first-of-type:before {
            content: $value;
        }
    }
    @each $icon, $value in $icons {
        li i.op-icon-#{$icon}:first-of-type:before {
            content: $value;
        }
    }
}

//メニューリスト
.op-list-menu {
    border-bottom: 1px solid $gray-f0f0f0;
    &:before {
        display: none;
    }
    li {
        position: relative;
        border-top: 1px solid $gray-f0f0f0;
        a {
            display: block;
            padding: 10px 10px 9px calc(10px + 1.5em);
            text-decoration: none;
            line-height: 1.6; //TODO:要確認
            @include _hover;

            @each $icon, $value in $icons {
                &.op-icon-#{$icon} {
                    padding-right: calc(10px + 1.5em);
                }
                &.op-icon-#{$icon}:before {
                    position: absolute;
                    right: 10px;
                    top: 50%;
                    -webkit-transform: translateY(-50%);
                    transform: translateY(-50%);
                    content: $value;
                }
            }
            i:first-of-type {
                display: block;
                position: absolute;
                left: 10px;
                top: calc(10px + .3em);
                width: 1.6em;
                @include _fontIcon;
                &:not(.op-icon) {
                    &:before {
                        content: $icon-arrow;
                    }
                }
            }
        }
    }
    //フレームの色
    @each $color, $value in $colors {
        &.op-border-#{$color},
        &.op-border-#{$color} li {
            border-color: $value;
        }
    }
    //背景色
    @each $color, $value in $colors {
        &.op-bg-#{$color} li a {
            background-color: $value;
        }
    }
    @each $icon, $value in $icons {
        &.op-icon-#{$icon} li a i:first-of-type:before {
            content: $value;
        }
    }
    @each $icon, $value in $icons {
        li a i.op-icon-#{$icon}:first-of-type:before {
            content: $value;
        }
    }
}
