//長方形のボタン
.op-button {
    @include _button;
    @include _hover;
    border-radius: 4px;
    //ボタンの背景色
    @each $color, $value in $colors {
        &.op-bg-#{$color} { background-color: $value; }
    }
    //ボタンの大きさ
    @each $size, $value in $button-sizes {
        &.op-button-#{$size} {
            @include _fontSize($size); //独自ファンクション式の HowTo
            height: $value;
            line-height: $value
        }
    }
}
//長方形で枠線ボタン
.op-button-border {
    @include _button;
    @include _hover;
    line-height: 36px;
    border-radius: 4px;
    border: 2px solid $gray-f0f0f0;
    //ボタンの枠線とテキストの色
    @each $color, $value in $colors {
        &.op-border-#{$color} {
            color: $value;
            border-color: $value;
        }
    }
    //ボタンの背景色
    @each $color, $value in $colors {
        &.op-bg-#{$color} { background-color: $value; }
    }
    //ボタンの大きさ
    @each $size, $value in $button-sizes {
        &.op-button-#{$size} {
            @include _fontSize($size); //独自ファンクション式の HowTo
            height: $value;
            line-height: $value - 4px;
        }
    }
}
//角丸のボタン
.op-button-round {
    @include _button;
    @include _hover;
    border-radius: 40px;
    //ボタンの背景色
    @each $color, $value in $colors {
        &.op-bg-#{$color} { background-color: $value; }
    }
    //ボタンの大きさ
    @each $size, $value in $button-sizes {
        &.op-button-#{$size} {
            font-size: map-get($text-sizes, $size); //Scss関数式の HowTo
            height: $value;
            line-height: $value;
            border-radius: $value;
        }
    }
}
//角丸で枠線ボタン
.op-button-round-border {
    @include _button;
    @include _hover;
    line-height: 36px;
    border-radius: 40px;
    border: 2px solid $gray-f0f0f0;
    //ボタンの枠線とテキストの色
    @each $color, $value in $colors {
        &.op-border-#{$color} {
            color: $value;
            border-color: $value;
        }
    }
    //ボタンの背景色
    @each $color, $value in $colors {
        &.op-bg-#{$color} { background-color: $value; }
    }
    //ボタンの大きさ
    @each $size, $value in $button-sizes {
        &.op-button-#{$size} {
            @include _fontSize($size); //独自ファンクション式の HowTo
            height: $value;
            line-height: $value - 4px;
        }
    }
}
