//基本の画像付きカード
.op-photo-card {
    display: block;
    overflow: hidden;
    border-radius: 4px;
    box-sizing: border-box;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);

    //背景色
    @each $color, $value in $colors {
        &.op-bg-#{$color} { background-color: $value; }
    }

    > figure {
        margin: 0;
        img {
            display: block;
            max-width: 100%;
            margin: 0;
        }
    }
}
a.op-photo-card {
    text-decoration: none;
    @include _hover;
}

//チェキ風カード
.op-photo-card-cheki {
    display: block;
    border: 1px solid $gray-f0f0f0;
    box-sizing: border-box;

    //フレームの色
    @each $color, $value in $colors {
        &.op-border-#{$color} { border-color: $value; }
    }

    //背景色
    @each $color, $value in $colors {
        &.op-bg-#{$color} { background-color: $value; }
    }

    > figure {
        margin: 0;
        img {
            display: block;
            max-width: 100%;
            margin: 0;
        }
    }
}
a.op-photo-card-cheki {
    text-decoration: none;
    @include _hover;
}

//アナログ写真風のカード（op-frame-photoは古い名称なので折を見て削除）
.op-photo-card-shadow,
.op-frame-photo {
    border: 10px solid $white;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
}
