//フレーム初期設定
.op-frame {
    box-sizing: border-box;
    padding: 24px;
    border: 4px solid $gray-f0f0f0;
    //角丸フレーム
    &.op-border-round {
        border-radius: 4px;
    }
    //フレームの色
    @each $color, $value in $colors {
        &.op-border-#{$color} { border-color: $value; }
    }
}
